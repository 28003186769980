import React from "react"
import SEO from "../components/seo"

//components
import Video from "../components/video"
import { motion, AnimatePresence } from "framer-motion"

const transition = { duration: 0.2, ease: [0.6, -0.05, 0.01, 0.9] }

const ShowReel = () => (
  <AnimatePresence
    exitBeforeEnter
    initial={{ opacity: 0 }}
    exit={{ opacity: 0 }}
    animate={{ opacity: 1 }}
  >
    <motion.div
      initial={{ opacity: 0 }}
      exit={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={transition}
    >
      <SEO title="Showreel" />
      <Video />
    </motion.div>
  </AnimatePresence>
)

export default ShowReel
