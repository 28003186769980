import React from "react"
import Fade from "react-reveal/Fade"
import Show from "../images/paaolmsventurestudioUI-UX.mp4"

const ShowReel = () => {
  return (
    <video autoPlay muted loop className="wrapperVideo">
      <source src={Show} type="video/mp4" />
    </video>
  )
}

const Video = () => (
  <>
    <div className="video">
      <div className="inner-video">
        <ShowReel />
      </div>
    </div>
  </>
)

export default Video
